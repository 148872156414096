import { RequestHelper } from '../utils/RequestHelper';
import * as ViAIndexedDB from '../utils/ViAIndexedDB';
import * as CacheDB from '../utils/CacheDB';

const { OFFLINE_REQUESTS } = ViAIndexedDB.OBJECT_STORE;

declare const self: ServiceWorkerGlobalScope;

self.addEventListener('install', (event: ExtendableEvent) => {
    event.waitUntil(CacheDB.init());
    event.waitUntil(ViAIndexedDB.database());
});

self.addEventListener('activate', (event) => {
    event.waitUntil(CacheDB.activate());
});

self.addEventListener('fetch', (event) => {
    // check if request is made by chrome extensions or web page
    // if request is made for web page url must contains http.
    if (!(event.request.url.indexOf('http') === 0)) {
        // skip the request. if request is not made with http protocol
        return;
    }

    const clonedRequest = event.request.clone();

    const { headers } = event.request;
    const isSSERequest = headers.get('Accept') === 'text/event-stream';

    if (isSSERequest) {
        return;
    }


    if (clonedRequest.method === 'GET') {
        event.respondWith(
            fetchAndUpdate(event.request).catch(
                async (error: Error) => {
                    const response = await CacheDB.match(event.request);

                    if (response) {
                        return response;
                    }

                    throw error;
                }
            )
        );

        return;
    }

    // attempt to send request normally
    event.respondWith(
        fetch(event.request.clone()).catch((error) => {
            const request = event.request.clone();
            const { method } = request;

            if (method === 'PUT' || method === 'POST') {
                // only save post requests in browser, if an error occurs
                return saveOfflineRequest(request, error);
            }

            throw error;
        })
    );
});

let internetStatusCheckSource : EventSource | undefined = undefined;

function initiateInternetStatusEventSource() {
    if (internetStatusCheckSource) {
        return;
    }

    const channel = new BroadcastChannel('internet-status');

    internetStatusCheckSource = new EventSource('/sse/internet-status');

    // addEventListener version
    internetStatusCheckSource.addEventListener('open', () => channel.postMessage({
        type: 'internet-status',
        isOnline: true,
    }));


    // addEventListener version
    internetStatusCheckSource.addEventListener('status', (e) => channel.postMessage({
        type: 'internet-status',
        isOnline: e.data === 'online',
    }));

    internetStatusCheckSource.addEventListener('error', (e) => {
        channel.postMessage({
            type: 'internet-status',
            isOnline: false,
        });

        internetStatusCheckSource?.close();
        internetStatusCheckSource = undefined;
        initiateInternetStatusEventSource();
    });
}


self.addEventListener('message', (event) => {
    const { data } = event;
    const { type } = data;

    if (type === 'check-offline-data') {
        event.waitUntil(isThereOfflineData().then((hasEntries) => {
            event.source?.postMessage({
                type: 'check-offline-data',
                hasEntries,
                finished: true
            });
        }));
    }

    if (type === 'sync-offline-data') {
        event.waitUntil(sendOfflineDataToServer().then(
            () => self.clients.matchAll().then(clients => {
                clients.forEach(client => client.postMessage({
                    type: 'check-offline-data',
                    hasEntries: false,
                    finished: true
                }));
            })
        ));
    }

    if (type === 'internet-status') {
        initiateInternetStatusEventSource();
    }

    // const { init, input } = event.data;
    // messageQueue[input] = init;
});


// self.addEventListener('sync', (event) => {
//     if (event.tag === 'sync-data') {
//         event.waitUntil(sendOfflineDataToServer().then(
//             () => self.clients.matchAll().then(clients => {
//                 clients.forEach(client => client.postMessage({
//                     type: 'check-offline-data',
//                     hasEntries: false,
//                     finished: true
//                 }));
//             })
//         ));
//     }
// });

const exclusionList = [
    /^.*(\/health(\/)?)$/,
    /^.*(\/files\/download(\/)).*$/,
];

function fetchAndUpdate(request: Request) {
    return fetch(request).then((response: Response) => {
        for (const excluded of exclusionList) {
            if (request.url.match(excluded) ) {
                return response;
            }
        }


        return CacheDB.put(request, response.clone()).then(() => response);
    }).catch((e) => {
        console.log('fetchAndUpdate', e);
        throw e;
    });
}

const saveOfflineRequest = (request: Request, error: Error) => RequestHelper.requestAsObject(request).then((requestAsObject) => {
    const offlineMode = 'x-offline-mode' in requestAsObject.headers ? requestAsObject.headers['x-offline-mode'] : null;

    if (offlineMode !== 'true') {
        throw error;
    }

    const memoryId = 'x-mem-id' in requestAsObject.headers ? requestAsObject.headers['x-mem-id'] : null;

    return saveMessage(
        request.clone().url,
        {
            body: requestAsObject.body,
            headers: requestAsObject.headers,
            method: requestAsObject.method
        },
        request.method,
        memoryId
    ).then((response) => CacheDB.updateOfflineCachedRequest(request, response, memoryId));
});

const saveMessage = (url: string, payload: Record<string, any>, method: string, memoryId: string) => {
    return ViAIndexedDB.getObjectStore(OFFLINE_REQUESTS, 'readwrite').then(
        (objectStore) => removeByMemoryId(objectStore, memoryId)
    ).then((objectStore) => new Promise((resolve, reject) => {
        const request = objectStore.add({
            url: url,
            payload,
            method,
            memoryId
        } as ViAIndexedDB.RequestToSave);

        request.onsuccess = () => resolve(request);

        request.onerror = (error) => reject(error);
    })).then(() => {
        const blob = new Blob([JSON.stringify(payload)], { type : 'application/json' });
        return new Response(blob, { status: 409, statusText: 'Offline operation' });
    });
};

const removeByMemoryId = (objectStore: IDBObjectStore, memoryId: string) => new Promise<IDBObjectStore>((resolve, reject) : void => {
    if (!memoryId) {
        return resolve(objectStore);
    }

    const memoryIdIndex = objectStore.index('by_memoryId');
    const memoryIdClean = memoryIdIndex.openKeyCursor(IDBKeyRange.only(memoryId));
    memoryIdClean.onsuccess = function() {
        const cursor = memoryIdClean.result;
        if (cursor) {
            objectStore.delete(cursor.primaryKey);
            cursor.continue();

            return;
        }

        resolve(objectStore);
    };
    memoryIdClean.onerror = (error) => reject(error);
});

const sendOfflineDataToServer = () => ViAIndexedDB.getObjectStore(OFFLINE_REQUESTS)
    .then((objectStore) => new Promise((resolve, reject) => {
        const savedRequests = new Array<ViAIndexedDB.SavedRequest>;
        const req = objectStore.openCursor();

        req.onsuccess = (event) => {
            const cursor = req.result;
            if (cursor) {
                // Keep moving the cursor forward and collecting saved requests.
                savedRequests.push(cursor.value);
                cursor.continue();
            } else {
                for (const savedRequest of savedRequests) {
                    // send them to the server one after the other
                    const requestUrl = savedRequest.url;
                    const init = savedRequest.payload;
                    fetch(requestUrl, init).then(function (response) {
                        if (response.status < 400) {
                            // remove it from the IndexedDB.
                            ViAIndexedDB.getObjectStore(OFFLINE_REQUESTS, 'readwrite').then((os) => os.delete(savedRequest.id));
                        }
                    }).catch(function (error) {
                        // This will be triggered if the network is still down.
                        // The request will be replayed again
                        // the next time the service worker starts up.
                        console.error('Send to Server failed:', error);
                        // since we are in a catch, it is important an error is
                        // thrown,so the background sync knows to keep retrying
                        // the send to server
                        reject(error);
                    });
                }

                resolve(true);
            }
        };
    }));

const isThereOfflineData = () => ViAIndexedDB.hasEntries(OFFLINE_REQUESTS);

// const isSameOrigin = (urlString) => {
//     const urlOrigin = (new URL(urlString)).origin;
//     return urlOrigin === self.location.origin;
// };