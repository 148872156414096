import { JsonHelper } from './JsonHelper';
import 'whatwg-fetch';

type RequestObject = Pick<Request, 'method'> & {
    headers: Record<string, any>;
    signal: Record<string, any>;
    body: string;
}

class RequestHelper {
    static requestAsObject = (request: Request) => {
        if (!(request instanceof Request)) {
            throw Object.assign(
                new Error(),
                { name: 'TypeError', message: 'Argument must be a Request object' },
            );
        }
        request = request.clone();

        return request.text().then((text) => ({
            ...JsonHelper.stringfiableObject(request),
            headers: Object.fromEntries(request.headers),
            signal: JsonHelper.stringfiableObject(request.signal),
            body: text,
        }) as RequestObject);
    }
}

export { RequestHelper };