const DATABASE_NAME = 'vincotte-via';

const OBJECT_STORE = {
    OFFLINE_REQUESTS: 'offline_requests',
};

export type SavedRequest = IDBObjectStore &
    RequestToSave & {
    id: string;
}

export type RequestToSave = {
    url: string;
    payload: Record<string, any>;
    method: string;
    memoryId: string | undefined;
}

const database = () => new Promise<IDBDatabase>((resolve, reject) => {
    const indexedDBOpenRequest = indexedDB.open(DATABASE_NAME);
    indexedDBOpenRequest.onerror = (error) => {
        // error creating db
        console.error('IndexedDB error:', error);
        reject(error);
    };

    indexedDBOpenRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const { result } = indexedDBOpenRequest;

        // This should only executes if there's a need to
        // create/update db.
        const store = result.createObjectStore(OBJECT_STORE.OFFLINE_REQUESTS, { autoIncrement:  true, keyPath: 'id' });
        store.createIndex('by_memoryId', 'memoryId');
    };

    // This will execute each time the database is opened.
    indexedDBOpenRequest.onsuccess = (event: Event) => {
        const { result } = indexedDBOpenRequest;
        resolve(result);
    };
});

const getObjectStore = (storeName: string, mode?: IDBTransactionMode | undefined) => {
    return database().then((db) => db.transaction(storeName, mode).objectStore(storeName));
};

const hasEntries = (storeName: string) => getObjectStore(storeName, 'readonly').then((objectStore) => new Promise<number>((resolve, reject) => {
    const count = objectStore.count();
    count.onsuccess = () => resolve(count.result);
    count.onerror = () => reject(count.error);
}).then((count) => count > 0));

export { database, getObjectStore, hasEntries, OBJECT_STORE };